import { InfraLocaleUtils } from 'tws-vue-components'

export const getSelectedLocale = () => {
  return InfraLocaleUtils.getSelectedLocale()
}

export const isLocaleEnglish = () => {
  return getSelectedLocale() === InfraLocaleUtils.LOCALES.EN
}

export const getIframeLocalisedURL = (url) => {
  //TODO: revert tommorrow
  // return isLocaleEnglish() && process.env.NODE_ENV !== 'production' ? url + '&target=SUSA_ENU' : url + '&target=SUSA'
  return isLocaleEnglish() ? url + '&target=SUSA_ENU' : url + '&target=SUSA'
}
